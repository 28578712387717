import Barba from 'barba';
import { TweenLite } from 'gsap';
import Plyr from 'plyr';
import  { lazysizes, optimumx } from 'lazysizes';
import { debounce } from 'lodash-es';

const body = document.body
const menu = document.querySelector('#menu');
const nav = document.querySelector('nav');
const navlinks = document.querySelector('.nav-link');
const overlay = document.querySelector('#overlay');
const overlaySvg = document.querySelector('#overlay svg');
const projectsCnt = document.querySelector('[data-namespace="projects"]');
const projects = document.querySelector('main[data-page="projects"]');

let titleShow = true; 
const mobile = isMobile();
let purchaseMade = false;
let scroll = null;

const style = {
    base: {
        fontSize: '1em',
        fontWeight: 200,
        color: "#32325d",
        padding: '0.3em'
    },
};

function init() {
    bindEvents();
    transitions();
    if (document.body.dataset.template === 'work') {
        introduction();
    }

    menu.addEventListener("click", () => {
        if (!nav.classList.contains('open')) {
            let openMenu = new TimelineLite();
            openMenu.add(TweenLite.to(nav, 0.5, {
                onStart: function() {
                    nav.style.display = 'block'
                },
                opacity: 1,
                ease: Power3.easeOut
            }));
            nav.classList.add('open');
        } else {
            closeMenu();
        }
    });
}

function bindEvents() {
    const images = document.querySelectorAll('img')

    images.forEach((image) => {
        image.addEventListener('contextmenu', (event) => {
            event.preventDefault();
        }, true); 
    });
}

function isMobile() {
    if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
|| /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) { 
        return true;
    } else {
        return false;
    }
}

function resizeInit() {
    adjustFiveHeight();
    adjustSixHeight();
}

function closeMenu() {
    let closeMenu = new TimelineLite();
    closeMenu.add(TweenLite.to(nav, 0.7, {
        opacity: 0,
        ease: Power3.easeOut,
        onComplete: function() {
            nav.style.display = 'none'
        }
    }));
    nav.classList.remove('open');
}

function introduction() {
    console.log('intro')
    const overlay = document.querySelector('#overlay');
    let overlayTween = new TimelineLite();
    overlayTween.add( TweenLite.to(overlay, 1.5, {
        backgroundColor: 'transparent',
        ease: Power3.easeOut,
        delay: 1,
        onComplete: function() {
            if (mobile) {
                hideTitle();
            }
        }
    }));    
}

function hideTitle() {
    console.log('hide')
    const title = document.querySelector('#overlay svg');
    let titleTween = new TimelineLite();
    titleTween.add( TweenLite.to(title, 1.5, {
        opacity: 0,
        ease: Power3.easeOut,
        onComplete: function() {
            overlay.classList.add('remove')
            titleShow = false;
        }
    }));
    document.body.classList.add('title-shown')
    
}

function productInit() {
    const sizeSelector = document.querySelector('#size-selector');

    sizeSelector.addEventListener("change", (e) => {
        // updateTotal()
        showInfo();
    });
} 

function adjustFiveHeight() {
    const containers = Array.from(document.querySelectorAll('[data-thumbnails="5"]'));
    containers.forEach((container) => {
        container.style.height = 'auto';
        const containerHeight = container.offsetHeight;
        if (container.children[4].classList.contains('landscape')) {
            const imageHeight = container.children[4].offsetHeight * 0.5;
            container.style.height = containerHeight - imageHeight + 'px';
        } else {
            const imageHeight = container.children[4].offsetHeight * 0.35;
            const difference = containerHeight - imageHeight;
            container.style.height = difference + 'px';
        }
    });
}

function adjustSixHeight() {
    const containers = Array.from(document.querySelectorAll('[data-thumbnails="6"]'));
    containers.forEach((container) => {
        container.style.height = 'auto';
        const containerHeight = container.offsetHeight;
        if (container.children[4].classList.contains('landscape')) {
            const imageHeight = container.children[5].offsetHeight * 0.5;
            container.style.height = containerHeight - imageHeight + 'px';
        } else {
            const imageHeight = container.children[5].offsetHeight * 0.35;
            const difference = containerHeight - imageHeight;
            container.style.height = difference + 'px';
        }
    });
}

function indicateMenu(container) {
    const page = container.dataset.namespace;
    const menuItem = document.querySelector(`#${page}-link`);
    const menuItems = Array.from(document.querySelectorAll('.nav-link'));

    menuItems.forEach((item) => {
        item.classList.remove('is-active');
    });

    if(menuItem) {
        menuItem.classList.add('is-active');
    } else if (page === 'product') {
        document.querySelector('#products-link').classList.add('is-active');
    }
}

function showInfo() {
    const select = document.querySelector('#size-selector');
    let selected = select.options[select.selectedIndex];

    updateTotal(selected.value);

    select.addEventListener("change", (e) => {
        selected = select.options[select.selectedIndex];
        updateTotal(selected.value);
    });
    const size = selected.getAttribute('data-size');
    const secondary = document.querySelector('.secondary');
    const options = Array.from(secondary.querySelectorAll('[data-size]'));
    const activeSize = secondary.querySelector('[data-size="' + size +'"]');
    options.forEach((option) => {
        option.classList.remove('active');
    });
    activeSize.classList.add('active')
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function productLandscape() {
    document.querySelector('.product-thumbnails[data-orientation=portrait]').style.height = '100%';
}

function plyrInit() {
    const players = Array.from(document.querySelectorAll('.js-player'));
    const wH = window.innerHeight;
    const mute = document.querySelector('#mute');

    players.map(player => new Plyr(player, {
        controls: ['controls', 'progress'],
    }));

    mute.addEventListener('click', function() {
        players.forEach((player) => {
            if(!player.paused) {
                player.muted = !player.muted;
            }
        });
    })
        
    scroll =  function(e) {
        let matched = false;
        players.forEach(function(player) {
            player.muted = true
            if (matched) return;
            let distance = player.getBoundingClientRect().top,
                playerHeight = player.offsetHeight,
                boundary = (wH - playerHeight) / 4,
                lowerBound = boundary,
                upperBound = boundary * 3;

            if (lowerBound < distance && distance < upperBound) {
                player.play();
                mute.classList.add('active');
                matched = true;
            } else {
                player.pause();
                mute.classList.remove('active');
            }
        })
        if (!mobile) {
            if (titleShow) {
                hideTitle();
            } else {
                return
            }  
        }
    };

    window.addEventListener('scroll', scroll, false);
}

function updateTotal(total) {
    const totalCnt = document.querySelector('.pay-total');
    totalCnt.innerHTML = total;
}

function stripeInit() {
    const stripe = Stripe('pk_test_PuMq6cb6v4Q3tVsmgcEciMjQ');
    const elements = stripe.elements();

    // Style submit button
    // document.querySelector('#payment-form button').classList = 'submit-form'
    
    const card = elements.create('card', {style});
    card.mount('#card-element');
    card.addEventListener('change', ({error}) => {
        const displayError = document.getElementById('card-errors');
        if (error) {
            displayError.textContent = error.message;
        } else {
            displayError.textContent = '';
        }
    });

    // Create a token or display an error when the form is submitted.
    const form = document.getElementById('payment-form');
    form.addEventListener('submit', async (event) => {
        event.preventDefault();
        disableCheckout();

        const tokenData = {
            name: document.querySelector('input[name="name"]').value
        }
        // options
        const {token, error} = await stripe.createToken(card, tokenData);
        if (error) {
            // Inform the customer that there was an error.
            const errorElement = document.getElementById('card-errors');
            errorElement.textContent = error.message;
            disableCheckout(true);
        } else {
            // Send the token to your server.
            stripeTokenHandler(token, event);
        }
    });

    const stripeTokenHandler = (token, event) => {
        const form = document.getElementById('payment-form');

        const hiddenSize = document.createElement('input');
        const sizeSelector = document.querySelector('#size-selector');
        const selectedSize =  sizeSelector.options[sizeSelector.selectedIndex].getAttribute('data-size');
        hiddenSize.setAttribute('type', 'hidden');
        hiddenSize.setAttribute('name', 'selectedSize');
        hiddenSize.setAttribute('value', selectedSize);
        form.appendChild(hiddenSize);

        const hiddenInput = document.createElement('input');
        hiddenInput.setAttribute('type', 'hidden');
        hiddenInput.setAttribute('name', 'stripeToken');
        hiddenInput.setAttribute('value', token.id);
        form.appendChild(hiddenInput);

        const hiddenCountry = document.createElement('input');
        const countrySelector = document.querySelector('#country-selector');
        const selectedCountry =  countrySelector.value;
        hiddenCountry.setAttribute('type', 'hidden');
        hiddenCountry.setAttribute('name', 'selectedCountry');
        hiddenCountry.setAttribute('value', selectedCountry);
        form.appendChild(hiddenCountry);
    
        submitForm(event, form);
    }


    const submitForm = (event, form) => {
        const errorCnt = document.querySelector('#card-errors');
        const errors = validateAddress();

        const request = new XMLHttpRequest();
        request.addEventListener("load", () => {
            if(request.readyState == XMLHttpRequest.DONE && request.status == 200) {
                let response = JSON.parse(request.responseText)
                let message = {
                    successMessage: response.outcome.seller_message,
                    description: response.description
                }
                displayThanks(message)
                
            } else {
                let response = JSON.parse(request.responseText)
                let message = response.message
                errorCnt.innerHTML = message
                disableCheckout(true);
            }
        });
        request.open('POST', event.target.action, true);
        request.timeout = 8000;
        request.ontimeout = () => {
            console.error('The request timed out.');
            errorCnt.innerHTML = 'The request timed out.';
        };

        if (!Array.isArray(errors) || !errors.length) {
            request.send(new FormData(event.target));
        } else {
            errors.forEach((error) => {
                // console.log(error)
                if (error === 'country') {
                    console.log('country')
                    document.querySelector('#country-selector').parentNode.classList.add('invalid');
                } else {
                    document.querySelector(`input[name="${error}"]`).classList.add('invalid');
                }
                // if (error.value === 'country') {
                //     console.log('not country')
                //     document.querySelector(`input[name="${error}"]`).classList.add('invalid');
                // }
                // if (error.value === 'country') {
                //     console.log('country')
                //     document.querySelector('#country-selector').parentNode.classList.add('invalid');
                // }
            })
            if (errors.length > 1) {
                errorCnt.innerHTML = `Please enter a valid ${errors.slice(0, -1).join(', ')+ ' and ' + errors.slice(-1)}.`;
            } else {
                errorCnt.innerHTML = `Please enter a valid ${errors}.`;
            }
            
            disableCheckout(true);
        }
    };
}

function validateAddress() {
    const inputs = Array.from(document.querySelectorAll('.stripe_validate'));
    const country = document.querySelector('#country-selector');
    let errors = [];

    for (let input of inputs) {
        input.classList.remove('invalid');
        if (!input.value) {
            errors.push(input.name);
        }
    }
    
    if (country.value === '') {
        errors.push(country.name);
    }

    return errors
}

function displayThanks(message) {
    const form = document.querySelector('#checkout-breakdown');
    const successMessage = document.querySelector('#success-message');
    const itemBought = document.querySelector('#item-bought');

    form.style.display = 'none';
    disableCheckout(true);
    successMessage.style.display = 'inline';
    itemBought.innerHTML = message.description;

    purchaseMade = true;
}

function disableCheckout(enable) {
    const checkoutOverlay = document.querySelector('#checkout-overlay');

    if (enable) {
        checkoutOverlay.style.display = 'none';
        setTimeout(() => {
            checkoutOverlay.classList.remove('disabled');
        }, 100);
    } else {
        checkoutOverlay.style.display = 'flex';
        setTimeout(() => {
            checkoutOverlay.classList.add('disabled');
        }, 100);
    }
}

function modalsInit() {
    const deliveryButton = document.querySelector('#delivery-link');
    const paymentButton = document.querySelector('#payment-link');
    const closeScreen = document.querySelector('#close-screen');
    const deliveryModalClose = document.querySelector('#delivery-close');
    const paymentModalClose = document.querySelector('#checkout-close');

    deliveryButton.addEventListener("click", function() {
        toggleModal('delivery');
    });
    paymentButton.addEventListener("click", function() {
        toggleModal('checkout');
    });
    closeScreen.addEventListener("click", function() {
        if (document.querySelector('#checkout-modal').classList.contains('active')) {
            toggleModal('checkout');
        } else if (document.querySelector('#delivery-modal').classList.contains('active')) {
            toggleModal('delivery');
        }
    });
    deliveryModalClose.addEventListener("click", function() {
        toggleModal('delivery');
    });
    paymentModalClose.addEventListener("click", function() {
        toggleModal('checkout');
    });
}

function toggleModal(name) {
    const modal = document.querySelector(`#${name}-modal`);
    const closeBg = document.querySelector('#close-screen');
    if (!modal.classList.contains('active')) {
        modal.classList.add('active');
        fadeIn(closeBg)
    } else {
        setTimeout(() => {
            modal.classList.remove('active');
            fadeOut(closeBg)
        }, 100)

        if (purchaseMade) {
            resetForm()
            purchaseMade = false;
        }
    }
}

function fadeOut(el){
    el.style.opacity = 1;

    (function fade() {
        if ((el.style.opacity -= .105) < 0) {
            el.style.display = "none";
        } else {
            requestAnimationFrame(fade);
        }
    })();
}

function fadeIn(el, display){
    el.style.opacity = 0;
    el.style.display = display || "block";

    (function fade() {
        var val = parseFloat(el.style.opacity);
        if (!((val += .105) > 1)) {
            el.style.opacity = val;
            requestAnimationFrame(fade);
        }
    })();
}

function transitions() {
    Barba.Utils.xhrTimeout = 10000;
    const FadeTransition = Barba.BaseTransition.extend({
        start: function() {
            Promise
                .all([this.newContainerLoading, this.fadeOut()])
                .then(this.fadeIn.bind(this));
        },
    
        fadeOut: function() {
            let deferred = Barba.Utils.deferred();
            TweenLite.to(this.oldContainer, 0.8, {
                opacity: 0,
                ease: Power3.easeOut,
                onComplete: function() {
                    deferred.resolve();
                }
            });
            return deferred.promise;
        },
        
        fadeIn: function() {
            let _this = this;
            let $el = this.newContainer;
        
            this.oldContainer.style.display = 'none'
            Object.assign($el.style,{opacity:"0",visibility:"visible"});
            TweenLite.to($el, 1.2, {
                opacity: 1,
                ease: Power3.easeOut, 
                onComplete: function() {
                    _this.done();
                }
            });
        }
    });
    const Work = Barba.BaseView.extend({
        namespace: 'work',
        onEnter: function() {
            if (!document.body.classList.contains('mobile')) {
                console.log('no mobile')
                plyrInit();
            }
            adjustFiveHeight();
            adjustSixHeight();

            window.scroll({
                top: 0, 
                left: 0 
            });
        },
        onEnterCompleted: function() {
            document.documentElement.dataset.template = ''
        },
        onLeave: function() {
            window.removeEventListener('scroll', scroll, false);
        }
    });
    const Products = Barba.BaseView.extend({
        namespace: 'products',
        onEnter: function() {
            document.documentElement.dataset.template = ''

            window.scroll({
                top: 0, 
                left: 0 
            });

        }
    });
    const Product = Barba.BaseView.extend({
        namespace: 'product',
        onEnter: function() {
            // .dataset
            document.documentElement.dataset.template = 'product'
            showInfo();
            stripeInit();
            modalsInit();
            productInit();

            window.scroll({
                top: 0, 
                left: 0 
            });

            window.addEventListener("orientationchange", function() {
                if(window.innerHeight < window.innerWidth && isMobile()){
                    productLandscape();
                }
            });

            if(window.innerHeight < window.innerWidth && isMobile()){
                productLandscape();
            }

        },
        onLeaveCompleted: function() {
            document.documentElement.dataset.template = ''
        }
    });
    const Information = Barba.BaseView.extend({
        namespace: 'information',
        onEnter: function() {
        },
    });
    Work.init();
    Products.init();
    Product.init();
    Information.init();
    Barba.Pjax.start();
    Barba.Prefetch.init();
    Barba.Pjax.getTransition = function() {
        return FadeTransition;
    };
    Barba.Dispatcher.on('linkClicked', function(HTMLElement, MouseEvent) {
        closeMenu();
    });
    Barba.Dispatcher.on('newPageReady', function(currentStatus, oldStatus, container) {
        indicateMenu(container);
    });
    Barba.Dispatcher.on('transitionCompleted', function(currentStatus, prevStatus) {
        bindEvents();
    });
}

window.addEventListener('resize', debounce(resizeInit, 200))

document.addEventListener("DOMContentLoaded", function(event) {
    init();
});

